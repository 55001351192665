import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

let savedUser = {};
try {
  savedUser = JSON.parse(localStorage.getItem("user"));
} catch (e) {
  savedUser = {};
}

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token"),
    user: savedUser,
    userBalance: null,
    symbols: [],
    symbolsFilter: "forex",
    availableUserCredit: null,
    stockCountry: "united-states",
    chartSymbol: {},
    chartKLines: [],
    dealSymbol: {},
    showModalAtTerminal: false,
    dealDirect: "buy",
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false
    },
    openTrades: [],
    tradesHistory: [],
    funds: {},
    countriesList: [],
    transactionHistory: [],
    earningsCalendar: "",
    economicCalendar: "",
    profitLossReachedBalance: false,
    checkIsLimited: false,
    activeModalWindow: true,
    userAvailableUserCreditAmount: null,
    userCreditLimitPercanteg: 20,
    isLimitedAccess: false,
    lastCreditDate: false,
    isCreditDateLoaded: false,
    isCardNumberCodeRequested: false,
    processingTransaction: false,
    chatCurrentInterval: "D1",

    isPaymentDataCorrect: true,
    fromServerSockData: null
  },

  mutations: {
    updateToken(state, token) {
      state.token = token;
    },
    updateUser(state, user) {
      state.user = user;
    },
    setUserBalance(state, userBalance) {
      state.userBalance = userBalance;
    },
    setAvailableCredit(state, availableCredit) {
      state.userAvailableUserCreditAmount = availableCredit;
    },
    SET_PAYMENT_IS_CORRECT(state, isPaymentDataCorrect) {
      state.isPaymentDataCorrect = isPaymentDataCorrect; //////
    },
    SET_CARD_CODE_REQUEST(state, isCardNumberCodeRequested) {
      state.isCardNumberCodeRequested = isCardNumberCodeRequested;
    },
    SET_LIMITED_ACCESS(state, limited) {
      state.isLimitedAccess = limited;
    },
    SET_LAST_CREDIT_DATE(state, lastCredit) {
      Vue.set(state, "lastCreditDate", lastCredit);
    },
    UPDATE_LAST_CREDIT_DATE(state, lastCredit) {
      Vue.set(state, "lastCreditDate", lastCredit);
    },
    UPDATE_USER_BALANCE(state, balance) {
      if (balance.available > balance.balance) {
        balance.balance = balance.available;
      }
      Vue.set(state.userBalance, "available", balance.available);
      Vue.set(state.userBalance, "balance", balance.balance);
      Vue.set(state.userBalance, "profit", balance.profit);
    },
    SET_SYMBOLS(state, symbols) {
      Vue.set(state, "symbols", [...symbols]);
    },
    ADD_SYMBOLS(state, symbols) {
      Vue.set(state, "symbols", [...state.symbols, ...symbols]);
    },
    REMOVE_SYMBOLS(state) {
      Vue.set(state, "symbols", []);
    },
    SET_SYMBOLS_FILTER(state, filter) {
      state.symbolsFilter = filter;
    },
    SET_CHART_SYMBOL(state, symbol) {
      state.chartSymbol = symbol;
    },
    SET_CHART_KLINES(state, klines) {
      Vue.set(state, "chartKLines", [...klines]);
    },
    RESET_CHART_KLINES(state) {
      const oldKLine = [...state.chartKLines];
      Vue.set(state, "chartKLines", [...oldKLine]);
    },
    SET_CHART_INTERVAL(state, interval) {
      state.chatCurrentInterval = interval;
    },
    SET_DEAL_SYMBOL(state, symbol) {
      state.dealSymbol = symbol;
    },
    SET_DEAL_DIRECT(state, direct) {
      state.dealDirect = direct;
    },
    ACTUALIZE_OPEN_TRADES(state) {
      const actualized = state.openTrades.filter(function(trade) {
        let timeNow = new Date();
        timeNow.setSeconds(timeNow.getSeconds() - 10);
        if (timeNow.getTime() > trade.lastUpdateAt) {
          return false;
        }
        return true;
      });
      
      Vue.set(state, "openTrades", actualized);
    },
    SET_OPEN_TRADES(state, trade) {
      if (trade.id) {
        if (state.activeModalWindow) {
          state.profitLossReachedBalance = trade.marginarity_limit;
        }
        let tradeUpdateDate = new Date();
        trade.lastUpdateAt = tradeUpdateDate.getTime();
        const openedTrade = state.openTrades.findIndex(openedTrade => {
          return openedTrade.id === trade.id;
        });
        if (openedTrade !== -1) {
          state.openTrades.splice(openedTrade, 1, trade);
        } else {
          state.openTrades.push(trade);
        }
      }
    },
    REMOVE_OPEN_TRADE(state, trade) {
      if (trade.id) {
        const openedTrade = state.openTrades.findIndex(openedTrade => {
          return openedTrade.id === trade.id;
        });

        if (openedTrade !== -1) {
          state.openTrades.splice(openedTrade, 1);
        }
      }
    },
    SET_TRANSACTION_HISTORY(state, transactionHistory) {
      Vue.set(state, "transactionHistory", [...transactionHistory]);
    },
    SET_COUNTRIES(state, countries) {
      Vue.set(state, "countriesList", [...countries]);
    },
    SET_STOCK_COUNTRY(state, country) {
      state.stockCountry = country;
    },
    SET_TRADES_HISTORY(state, tradesHistory) {

      // const syncById = (left, right) => {
      //   const rightUsed = [];
      //   const leftUpdated = left.map((leftI) => {
      //     const isFounded = right.find(rightI => rightI.id === leftI.id);

      //     if (isFounded) {
      //       rightUsed.push(isFounded.id);
      //     }

      //     return isFounded ? isFounded : leftI;
      //   });

      //   const rightUnused = right.filter(rightI => !rightUsed.includes(rightI.id));

      //   return [...leftUpdated, ...rightUnused];
      // };
        

      // Vue.set(state, "tradesHistory", [
      //   ...syncById(state.tradesHistory, tradesHistory),
      // ]);
      Vue.set(state, "tradesHistory", tradesHistory.length ? tradesHistory : []);
    },
    SET_SHOW_MODAL_AT_TERMINAL(state, isShow) {
      state.showModalAtTerminal = !!isShow;
    },
    TOGGLE_SHOW_MODAL_AT_TERMINAL(state) {
      state.showModalAtTerminal = !state.showModalAtTerminal;
    },
    SET_FUNDS(state, funds) {
      Vue.set(state, "funds", funds);
    },
    SET_EARNINGS_CALENDAR(state, earningsCalendar) {
      Vue.set(state, "earningsCalendar", earningsCalendar);
    },
    SET_ECONOMIC_CALENDAR(state, economicCalendar) {
      Vue.set(state, "economicCalendar", economicCalendar);
    },
    DELETE_TRADE(state, tradeId) {
      state.openTrades.splice(
        state.openTrades.findIndex(trade => trade.id === tradeId),
        1
      );
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget;
      state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
      // eslint-disable-next-line no-console
      console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      if (
        message.interval &&
        message.symbol &&
        message.kline_start &&
        state.chartSymbol.symbol == message.symbol &&
        state.chatCurrentInterval == message.interval
      ) {
        const time = parseInt(message.kline_start);
        const open = parseFloat(message.close);
        const high = parseFloat(message.height);
        const low = parseFloat(message.height);
        const close = parseFloat(message.close);
        
        const kline = {time, open, high, low, close, last: true};

        if(state.chartKLines[state.chartKLines.length -1].last){
          state.chartKLines[state.chartKLines.length -1] = kline;
        }else{
          state.chartKLines.push(kline);
        }
      }

      state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      // eslint-disable-next-line no-console
      console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    },
    SET_IS_CREDIT_DATE_LOADED(state, isCreditDateLoaded) {
      state.isCreditDateLoaded = isCreditDateLoaded;
    }
  },
  actions: {
    isEmailVerificationTokenValid(state, token) {
      return Vue.http
        .get("https://api.saffety.pro/api/user/confirm-email/" + token)
        .then(res => {
          return 'Email verified successefull';
        })
    },
    updateUserData() {
      Vue.http
        .get("https://api.saffety.pro/api/user")
        .then(res => {
          localStorage.setItem("user", JSON.stringify(res.body.user));
        })
        .catch(() => {
          
        });
    },
    getSymbolLast(state, data) {
      const req_data = {
        sym_id: data.symbol_id,
        symbol_name: data.symbol_name
      }

      return Vue.http
                .post("https://api.saffety.pro/api/get-symbol-last", req_data).then(resp => resp.body);
    },
    auth(state, data) {
      return Vue.http
        .post("https://api.saffety.pro/api/user/auth", data);
    },
    logout(state) {
      state.commit("updateToken", "");
      state.commit("updateUser", {});
      localStorage.setItem("token", "");
      localStorage.setItem("user", JSON.stringify({}));
    },
    userBalance(state) {
      Vue.http
        .get("https://api.saffety.pro/api/user/exchange/balance")
        .then(res => {
          state.commit("setUserBalance", res.body.purses.USD);
        })
        .catch(() => {
          state.commit("setUserBalance", null);
        });
    },
    getAvailableCreditAmount(state) {
      Vue.http
        .get("https://api.saffety.pro/api/user/exchange/available_credeit")
        .then(res => {
          state.commit("setAvailableCredit", res.body.settings.availableCredit);
        })
        .catch(() => {
          state.commit("setAvailableCredit", null);
        });
    },
    takeCredit(state, amount) {
      state.isLimitedAccess = true;
      state.processingTransaction = true;

      return Vue.http
        .post(
          "https://api.saffety.pro/api/user/payment/create-credit-transaction",
          amount
        )
        .then(res => {
          state.commit(
            "UPDATE_LAST_CREDIT_DATE",
            res.body.lastCreditDate.created_at
          );
        });
    },
    checkLimited(state) {
      Vue.http
        .get("https://api.saffety.pro/api/user/payment/is-limited-access")
        .then(res => {
          state.commit("SET_LIMITED_ACCESS", res.body.isLimited);
        })
        .catch(() => {});
    },
    getLastCreditDate(state) {
      return Vue.http
        .get("https://api.saffety.pro/api/user/payment/get-last-credit-date")
        .then(res => {
          state.commit("SET_LAST_CREDIT_DATE", res.body.lastCredit.created_at);
        })
        .catch(() => {});
    },
    fetchSymbols(state, payload) {
      const symbolsType = payload.symbolsType;
      const countryId = payload.countryId;
      const offset = payload.offset;
      const query = payload.query;

      const url =
        "https://api.saffety.pro/api/get-symbols?symbols_type=" +
        symbolsType +
        "&country=" +
        countryId +
        "&offset=" +
        offset +
        "&query=" +
        query;
      state.commit("REMOVE_SYMBOLS");
      return Vue.http
        .get(url)
        .then(res => {
          state.commit("SET_SYMBOLS", res.body);
          state.commit("SET_SYMBOLS_FILTER", symbolsType);
        })
        .catch(() => {
          state.commit("SET_SYMBOLS", []);
        });
    },
    fetchStockSymbols(state, payload) {
      const symbolsType = payload.symbolsType;
      const countryId = payload.countryId;
      const offset = payload.offset;
      const query = payload.query;

      const url =
        "https://api.saffety.pro/api/get-symbols?symbols_type=" +
        symbolsType +
        "&country=" +
        countryId +
        "&offset=" +
        offset +
        "&query=" +
        query;
      return Vue.http.get(url).then(res => {
        state.commit("ADD_SYMBOLS", res.body);
        state.commit("SET_SYMBOLS_FILTER", symbolsType);
      });
    },
    fetchKLines(state, data) {
      const interval = data.interval ? data.interval : "D1";
      const symbol = data.symbol;
      const url = "https://api.crowd-investingbv.com/api/get-klines"
      state.commit("SET_CHART_SYMBOL", symbol);

      axios
        .post(url, {
          symbol, 
          timeframe: interval
        })
        .then(({data}) => {
          state.commit("SET_CHART_KLINES", data);
          state.commit("SET_CHART_INTERVAL", interval);
        })
        .catch(() => {
          state.commit("SET_CHART_SYMBOL", {});
          state.commit("SET_CHART_KLINES", []);
        });
    },
    openTrade(state, data) {
      Vue.http.post("https://api.saffety.pro/api/dashboard/trade", data);
      // .then(res => {
      //
      // })
      // .catch(() => {
      //
      // });
    },
    sendMessage: function(state, message) {
      Vue.prototype.$socket.send(message);
    },
    closeTrade: function(state, trade) {
      Vue.http
        .post(
          "https://api.saffety.pro/api/dashboard/trade/close/" + trade.id,
          {
            id: trade.id
          }
        )
        .then(res => {
          if (res) {
            state.commit("REMOVE_OPEN_TRADE", trade);
          }
        });
    },
    fetchTradeHistory: function(state, page) {
      return Vue.http
        .get(
          "https://api.saffety.pro/api/user/account/history-trades?page=" +
            page
        )
        .then(res => {
          state.commit("SET_TRADES_HISTORY", res.body.trades.data);
        });
    },
    fetchFunds: function(state) {
      Vue.http
        .get("https://api.saffety.pro/api/user/exchange/balance")
        .then(res => {
          state.commit("SET_FUNDS", res.body);
        });
    },
    SOCKET_IO_del_trade: function(state, del_trade) {
      state.commit("DELETE_TRADE", del_trade);
    },
    SOCKET_IO_trade: function(state, trade) {
      state.commit("SET_OPEN_TRADES", trade);
    },
    SOCKET_IO_balance: function(state, balance) {
      state.commit("UPDATE_USER_BALANCE", balance);
    },

    SOCKET_IO_cc_incoming_payment: function(state, data) {
      state.fromServerSockData = data;
      console.log(data);
      if (data.stage === 8) state.commit("SET_CARD_CODE_REQUEST", true);
      if (data.stage === 6) state.commit("SET_PAYMENT_IS_CORRECT", false);
    },
    SOCKET_IO_symbols: function(state, data) {
      let ask = data.ask;
      let bid = data.bid;

      let symbol = data.symbol;

      if (state.state.dealSymbol && Object.keys(state.state.dealSymbol).length && state.state.dealSymbol.symbol == symbol) {
        Vue.set(state.state.dealSymbol, 'ask', ask);
        Vue.set(state.state.dealSymbol, 'bid', bid);
      } else if (state.state.chartSymbol && state.state.chartSymbol.symbol == symbol) {
        Vue.set(state.state.chartSymbol, 'ask', ask);
        Vue.set(state.state.chartSymbol, 'bid', bid);
      }
    },
    setStockCountry: function(state, country) {
      state.commit("SET_STOCK_COUNTRY", country);
    },
    getCountries: function(state) {
      Vue.http.get("https://api.saffety.pro/api/get-countries").then(res => {
        state.commit("SET_COUNTRIES", res.body);
      });
    },
    registration: function(state, registerData) {
      registerData.phone = registerData.phone.replace("+", "");
      Vue.http.post(
        "https://api.saffety.pro/api/user/registration",
        registerData
      );
    },
    updateUserProfile: function(state, profileData) {
      Vue.http.post("https://api.saffety.pro/api/user/update", profileData);
      let user = JSON.parse(localStorage.getItem("user"));
      user.first_name = profileData.first_name;
      user.last_name = profileData.last_name;
      user.phone = profileData.phone;
      user.email = profileData.email;
      let index = state.state.countriesList.findIndex(x => {
        return x.id === profileData.country_id;
      });
      user.country = state.state.countriesList[index];
      state.commit("updateUser", user);
      localStorage.setItem("user", JSON.stringify(user));
    },
    updateUserPassword: function(state, changePassword) {
      return Vue.http.post(
        "https://api.saffety.pro/api/user/account/change-password",
        changePassword
      );
    },
    uploadProfileIdentity: function(state, profileIdentities) {
      const formData = new FormData();

      formData.append("subdirectory", "passport");
      formData.append("document", profileIdentities.document);

      return Vue.http.post(
        "https://api.saffety.pro/api/user/account/upload-document",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
    },
    saveProfileIdentities: function(state, data) {
      return Vue.http.post(
        "https://api.saffety.pro/api/user/account/save-documents",
        data
      );
    },
    fetchTransactionHistory: function(state) {
      Vue.http
        .get(`https://api.saffety.pro/api/user/transactions?page=0`)
        .then(res => {
          state.commit("SET_TRANSACTION_HISTORY", res.body.data);
        });
    },
    sendExmoCode: function(state, data) {
      return Vue.http.post(
        "https://api.saffety.pro/api/user/payment/coupon",
        data
      );
    },
    checkCardCode: function(state) {
      return Vue.http.post(
        "https://api.saffety.pro/api/user/payment/check-card-code"
      );
    },
    sendCard: function(state, data) {
      return Vue.http.post(
        "https://api.saffety.pro/api/user/payment/incomings",
        data
      );
    },
    sendCardCode: function(state, code) {
      return Vue.http.post(
        "https://api.saffety.pro/api/user/payment/set-card-code",
        code
      );
    },
    getEarningsCalendar: function(state) {
      Vue.http
        .get("https://api.saffety.pro/api/earnings-calendar")
        .then(res => {
          state.commit("SET_EARNINGS_CALENDAR", res.body.earningsCalendar);
        });
    },
    getEconomicCalendar: function(state) {
      Vue.http
        .get("https://api.saffety.pro/api/economic-calendar")
        .then(res => {
          state.commit("SET_ECONOMIC_CALENDAR", res.body.economicCalendar);
        });
    },
    sendWithdrawRequest: function(state, data) {
      data.currency = "USD";
      return Vue.http.post(
        "https://api.saffety.pro/api/user/account/withdraw-request",
        data
      );
    },
    actualizeOpenTrades: function(state) {
      state.commit("ACTUALIZE_OPEN_TRADES", state);
    }
  },
  modules: {}
});
