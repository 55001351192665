<template>
  <div class="row mrl-0">
    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12 asset-color text-center mb-3">
      <div class="row" v-show="!Object.keys(dealSymbol).length">
        <div class="trade-symbol col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <span class="d-inline-block fw-bold fz-14 colorize">
            <span>{{ chartSymbolName.front_view }}</span>
          </span>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 text-align-right">
          <span class="d-inline-block fw-bold fz-14 mr-1 colorize">
            <span>{{ chartSymbolName.bid }}</span>
          </span>
          <span class="d-inline-block fw-bold fz-14 ml-1">
            <button @click="selectSymbol(chartSymbolName, 'sell')" class="btn btn-sm btn-danger">
              {{ $t("dashboard.sell") }}
            </button>
          </span>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 text-align-left">
          <span class="d-inline-block fw-bold fz-14 mr-1 colorize">
            <span>{{ chartSymbolName.ask }}</span>
          </span>
          <span class="d-inline-block fw-bold fz-14 ml-1">
            <button @click="selectSymbol(chartSymbolName, 'buy')" class="btn btn-sm btn-primary">
              {{ $t("dashboard.buy") }}
            </button>
          </span>
        </div>
      </div>

      <div class="row" v-show="!!Object.keys(dealSymbol).length">
        <div class="trade-symbol col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <span class="d-inline-block fw-bold fz-14">
            <span>{{ dealSymbol.front_view }}</span>
          </span>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 text-align-right">
          <span class="d-inline-block fw-bold fz-14 mr-1">
            <span>{{ dealSymbol.bid }}</span>
          </span>
          <span class="d-inline-block fw-bold fz-14 ml-1">
            <button @click="selectSymbol(dealSymbol, 'sell')" class="btn btn-sm btn-danger">
              {{ $t("dashboard.sell") }}
            </button>
          </span>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 text-align-left">
          <span class="d-inline-block fw-bold fz-14 mr-1">
            <span>{{ dealSymbol.ask }}</span>
          </span>
          <span class="d-inline-block fw-bold fz-14 ml-1">
            <button @click="selectSymbol(dealSymbol, 'buy')" class="btn btn-sm btn-primary">
              {{ $t("dashboard.buy") }}
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 text-align show-dropdown hide-dropdown">
      <div class="dropdown">
        <button
          class="rounded-pill btn btn-sm btn-warning dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ interval }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <a
              class="rounded-pill btn btn-sm me-1 d-inline-block"
              :class="{ 'btn-warning': interval == 'M1', 'btn-primary': interval != 'M1' }"
              @click="fetchKlinesWithInterval('M1')"
            >1M</a>
          </li>
          <li>
            <a
              class="rounded-pill btn btn-sm me-1 d-inline-block"
              :class="{ 'btn-warning': interval == 'M15', 'btn-primary': interval != 'M15' }"
              @click="fetchKlinesWithInterval('M15')"
            >15M</a>
          </li>
          <li>
            <a
              class="rounded-pill btn btn-sm me-1 d-inline-block"
              :class="{ 'btn-warning': interval == 'M30', 'btn-primary': interval != 'M30' }"
              @click="fetchKlinesWithInterval('M30')"
            >30M</a>
          </li>
          <li>
            <a
              class="rounded-pill btn btn-sm me-1 d-inline-block"
              :class="{ 'btn-warning': interval == 'H1', 'btn-primary': interval != 'H1' }"
              @click="fetchKlinesWithInterval('H1')"
            >1H</a>
          </li>
          <li>
            <a
              class="rounded-pill btn btn-sm me-1 d-inline-block"
              :class="{ 'btn-warning': interval == 'D1', 'btn-primary': interval != 'D1' }"
              @click="fetchKlinesWithInterval('D1')"
            >1D</a>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
      <vue-element-loading
        :active="showSpinner"
        spinner="bar-fade-scale"
        size="64"
        background-color="#efeffd"
        color="#ffc008"
      />
      <!-- Используем ref для получения DOM-элемента -->
      <div id="trading-view-graph" ref="graphContainerWidth"></div>
    </div>

    <div class="show-intervals hide-intervals text-align">
      <a
        class="rounded-pill btn btn-sm me-1 d-inline-block"
        :class="{ 'btn-warning': interval == 'M1', 'btn-primary': interval != 'M1' }"
        @click="fetchKlinesWithInterval('M1')"
      >1M</a>
      <a
        class="rounded-pill btn btn-sm me-1 d-inline-block"
        :class="{ 'btn-warning': interval == 'M15', 'btn-primary': interval != 'M15' }"
        @click="fetchKlinesWithInterval('M15')"
      >15M</a>
      <a
        class="rounded-pill btn btn-sm me-1 d-inline-block"
        :class="{ 'btn-warning': interval == 'M30', 'btn-primary': interval != 'M30' }"
        @click="fetchKlinesWithInterval('M30')"
      >30M</a>
      <a
        class="rounded-pill btn btn-sm me-1 d-inline-block"
        :class="{ 'btn-warning': interval == 'H1', 'btn-primary': interval != 'H1' }"
        @click="fetchKlinesWithInterval('H1')"
      >1H</a>
      <a
        class="rounded-pill btn btn-sm me-1 d-inline-block"
        :class="{ 'btn-warning': interval == 'D1', 'btn-primary': interval != 'D1' }"
        @click="fetchKlinesWithInterval('D1')"
      >1D</a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createChart } from 'lightweight-charts';
import moment from "moment"; 
import BuySellDeal from "./BuySellDeal.vue";

export default {
  name: "TradeGraph",
  components: {
    BuySellDeal
  },
  data() {
    return {
      interval: 'D1',
      lastInterval: null,
      chartObj: null,
      isShow: false,
      showIntervalIsLoading: true,
    };
  },
  computed: {
    ...mapState(["chartSymbol", "chartKLines", "dealSymbol"]),
    showSpinner() {
      return this.showIntervalIsLoading;
    },
    chartSymbolName() {
      return this.chartSymbol ? this.chartSymbol : {};
    },
    getKLineDigits() {
      return this.chartSymbol ? this.chartSymbol.digits : 2;
    },
    preparedKLines() {
      if (!this.chartKLines || !this.chartKLines.length) {
        return [];
      }
      return this.chartKLines
        .filter(elem =>
          elem.time != null &&
          elem.open != null &&
          elem.high != null &&
          elem.low != null &&
          elem.close != null
        )
        .map(elem => ({
          time: Number(elem.time), // Unix timestamp (в секундах)
          open: Number(elem.open),
          high: Number(elem.high),
          low: Number(elem.low),
          close: Number(elem.close)
        }))
        .sort((a, b) => a.time - b.time); // сортировка по времени
    }
  },
  watch: {
    chartKLines: function(newKLine, oldKLine) { 
      this.updateChartKLines();
    }, 
  },
  methods: {
    selectSymbol: function(symbol, direct) {
      this.$store.commit("SET_DEAL_SYMBOL", symbol);
      this.$store.commit("SET_DEAL_DIRECT", direct);
      this.$store.commit("SET_SHOW_MODAL_AT_TERMINAL", true);
    },
    updateChartKLines() {
      this.showIntervalIsLoading = true;
      this.chartObj.setData(this.preparedKLines);
      this.showIntervalIsLoading = false;
    },
    fetchKlinesWithInterval: function(e) {
      //eslint-ignore-next-line no-console
      if (this.interval != e) {
        this.interval = e;
        this.$store.dispatch("fetchKLines", {
          symbol: this.chartSymbol,
          interval: e
        });
        this.$store.dispatch(
          "sendMessage",
          JSON.stringify({
            join:
              "klines_" +
              this.interval.toLowerCase() +
              "_" +
              this.chartSymbol.symbol.toLowerCase(),
            notificator: "notificator"
          })
        );
      }
    },
  },
  mounted() {
      const graphContainerWidth = this.$refs.graphContainerWidth.clientWidth + 15;
      let height = 400;
      if (graphContainerWidth < 600) {
        height = 200;
      } else if(graphContainerWidth < 800) {
        height = 300;
      }

      const chart = createChart('trading-view-graph', { 
          width: graphContainerWidth, 
          height: height,
          layout: {
              backgroundColor: '#ffffff',
              textColor: '#000000',
          },
          grid: {
            horzLines: {
              color: '#d2d2d2',
            },
            vertLines: {
              color: '#d2d2d2',
            },
          },
          timeScale: {
            rightOffset: 0,
            barSpacing: 10,
            fixLeftEdge: true,
            lockVisibleTimeRangeOnResize: true,
            borderVisible: true,
            borderColor: '#d2d2d2',
            visible: true,
            timeVisible: true,
            secondsVisible: true,
          },
          priceFormat: {
              type: 'custom',
              minMove: '0.000001',
              formatter: (price) => {
                  if (price < 0.000001) return parseFloat(price).toPrecision(8)
                  else if (price >= 0.000001 && price < 1) return parseFloat(price).toPrecision(6)
                  else return parseFloat(price).toPrecision(6)
              }
          }, 
          priceScale: {
              autoScale: true
          },
          localization: {
              locale: 'en-US',
              priceFormatter: (price) => {
                if (price < 0.000001) return parseFloat(price).toPrecision(8);
                else if (price >= 0.000001 && price < 1) return parseFloat(price).toPrecision(6);
                else return parseFloat(price).toPrecision(6);
              }
          },
      });

      const candleChart = chart.addCandlestickSeries({
          upColor: '#90deff',
          downColor: 'red',
          borderDownColor: 'red',
          borderUpColor: '#90deff',
          wickDownColor: 'red',
          wickUpColor: '#90deff',
      });

      this.chartObj = candleChart;
    },
};
</script>

<style scoped>
  .mrl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .asset-color {
    color: #343434;
  }

  .trade-symbol > span {
    margin-right: 10px;
    margin-top: 8px;
  }

  .trade-symbol {
    margin-bottom: 10px;
  }

  .ml-1 {
    margin-left: 10px;
  }

  .mr-1 {
    margin-right: 10px;
  }

  @media only screen and (max-width: 992px){
    .text-align{
      text-align: center;
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: 992px) {
    .trade-symbol {
      margin-bottom: 0;
    }

    .text-align {
      text-align: right;
      margin-top: 0;
    }
  }

@media only screen and (max-width: 992px) {
  .hide-dropdown {
    display: none;
  }
  .show-intervals {
    display: block;
  } 
}

@media only screen and (min-width: 992px) {
  .hide-intervals {
    display: none;
  }

  .show-dropdown {
    display: block;
    min-width: 72px; 
  }
}
.show-dropdown a {
  width: 100%;
}
  .dropdown-menu li {
    margin-top: 12px;
    text-align: right;
    min-width: 72px; 
  }

  .dropdown-menu {
    background-color: transparent;
    border: none;
    min-width: 72px; 
  }

  .text-align-right {
    text-align: right;
  }

  .text-align-left {
    text-align: left;
  }
  .colorize{
    color: rgb(34, 34, 34);
  }
</style>
